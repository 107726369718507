import { render, staticRenderFns } from "./ProjectList.vue?vue&type=template&id=7981c6ca&"
import script from "./ProjectList.vue?vue&type=script&lang=js&"
export * from "./ProjectList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardText,VChip,VChipGroup,VCol,VContainer,VDivider,VRow})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
